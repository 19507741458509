import {
  CheckCircleTwoTone,
  FileTextOutlined,
  LockOutlined,
  MenuOutlined,
  PlusOutlined,
  UserOutlined,
} from '@ant-design/icons';
import styled from '@emotion/styled';
import {
  Alert,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Descriptions,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Tooltip,
  Upload,
  UploadFile,
} from 'antd';
import {FilterValue} from 'antd/es/table/interface';
import {red} from '@ant-design/colors';
import {API} from 'aws-amplify';
import dayjs, {Dayjs} from 'dayjs';
import saveAs from 'file-saver';
import JSZip from 'jszip';
import {FC, useCallback, useEffect, useMemo, useState} from 'react';
import {Link} from 'react-router-dom';
import {
  formatCurrency,
  getDateFormat,
  LANGUAGE,
  TranslateFn,
  useTranslation,
} from '../../translation';
import {DEFAULT_API_NAME, useApi, useUserIsAdmin} from '../../util/Auth';
import {
  DayjsDateRangeToTimestampRange,
  TimestampToDayjsDate,
} from '../../util/date';
import {CheckInstructions} from '../CheckInstructions';
import {CardFormValues} from '../entity/OwnerOrderCards';
import {GenerateInvoicePDF} from '../GenerateInvoicePDF';
import {ApartmentCodeSelect} from '../input/ApartmentCodeSelect';
import {CountrySelect} from '../input/CountrySelect';
import {getIntervalEndDateRule} from '../input/IntervalInput';
import {OwnerSelect} from '../input/OwnerSelect';
import {ContentDiv} from '../layout/ContentDiv';
import {WireTransferInstructions} from '../WireTransferInstructions';
import {ApartmentRecord} from './Apartment';
import {
  ArticleRecord,
  ArticleType,
  ArticleTypeAllowsProxy,
  ArticleTypeLabel,
  ArticleTypeRequireArticleSelection,
  ArticleTypeRequireNamedCards,
  ArticleTypeRequiresValidityInterval,
  isArticleRecordSeasonal,
  isArticleValuesQuota,
  OwnerSelectableArticleTypes,
  PeriodicArticleType,
  SeasonalArticleType,
} from './Article';
import {getOrderLineColumns, OrderLineRecord} from './OrderLine';
import {OwnerRecord} from './Owner';
import {SeasonRecord} from './Season';
import {
  booleanFilters,
  flattenObject,
  getDefaultSorter,
  getFiltersForAPI,
  TableColumnsProp,
  TableCreateModal,
  TableFormProps,
  TableList,
  TableUpdateModal,
} from './Table';
import {
  getEffectiveEndDate,
  getOrderCompositions,
  openApiUrlInNewTab,
} from './util';
import {useStateFn} from '../useStateFn';

const singularRoute = '/order';
const pluralRoute = '/orders';

const ASSET_ENDPOINT = process.env.REACT_APP_ASSET_ENDPOINT;

const ORDER_PROXY_PDF_PATH = [
  ASSET_ENDPOINT,
  'procuration_cartes_piscine.pdf',
].join('/');

const proxyMimeTypes = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'application/pdf',
];

export enum PaymentType {
  PAYPAL = 1,
  /** @DEPRECATED */
  SUMUP,
  WIRE_TRANSFER,
  CHECK,
  CASH,
  POS,
  SOGE,
}

export const PaymentTypeLabel: {[type in PaymentType]: string} = {
  [PaymentType.PAYPAL]: 'order.paymentType.paypal',
  [PaymentType.SUMUP]: 'order.paymentType.sumup',
  [PaymentType.WIRE_TRANSFER]: 'order.paymentType.wire',
  [PaymentType.CHECK]: 'order.paymentType.check',
  [PaymentType.CASH]: 'order.paymentType.cash',
  [PaymentType.POS]: 'order.paymentType.pos',
  [PaymentType.SOGE]: 'order.paymentType.soge',
};

export const getOwnerPaymentTypes = (
  _articleType: ArticleType,
): PaymentType[] => [PaymentType.SOGE];
export const AdminPaymentTypes = [
  PaymentType.CHECK,
  PaymentType.CASH,
  PaymentType.POS,
  PaymentType.WIRE_TRANSFER,
];

export enum OrderAccountingStatus {
  SUBMITTED = 1,
  PAID,
  PRINTED,
  ACCOUNTED,
  FAILED,
}

export const OrderAccountingStatusLabel: {
  [type in OrderAccountingStatus]: string;
} = {
  [OrderAccountingStatus.SUBMITTED]: 'orders.accountingStatus.submitted',
  [OrderAccountingStatus.PAID]: 'orders.accountingStatus.paid',
  [OrderAccountingStatus.PRINTED]: 'orders.accountingStatus.printed',
  [OrderAccountingStatus.ACCOUNTED]: 'orders.accountingStatus.accounted',
  [OrderAccountingStatus.FAILED]: 'orders.accountingStatus.failed',
};

export enum OrderFabricationStatus {
  SUBMITTED = 1,
  AVAILABLE,
  DELIVERED,
}

export const OrderFabricationStatusLabel: {
  [type in OrderFabricationStatus]: string;
} = {
  [OrderFabricationStatus.SUBMITTED]: 'orders.fabricationStatus.submitted',
  [OrderFabricationStatus.AVAILABLE]: 'orders.fabricationStatus.available',
  [OrderFabricationStatus.DELIVERED]: 'orders.fabricationStatus.delivered',
};

export enum OrderType {
  INVOICE = 1,
  CREDIT,
}

export const OrderTypeLabel: {[type in OrderType]: string} = {
  [OrderType.INVOICE]: 'orders.type.invoice',
  [OrderType.CREDIT]: 'orders.type.credit',
};

export interface OrderFormValues {
  orderNumber: string;
  apartmentId: string;
  apartmentCode: string;
  articleType: ArticleType;
  articleId?: string;
  validityStart?: number | Dayjs;
  validityEnd?: number | Dayjs;
  effectiveValidityEnd?: number | Dayjs;
  isProxy: boolean;
  proxy?: string;
  proxyDocuments?: UploadFile[];
  comment?: string;
  paymentType: PaymentType;
  seasonId?: string;
  checkoutId?: string;
  isRenewal?: boolean;
  recipientName?: string;
  recipientAddr?: string;
  recipientZipCode?: string;
  recipientCity?: string;
  recipientCountry?: string;
  renewalCardNo?: string;
  iHaveAClone?: boolean;
}

export interface AdminOrderFormValues extends OrderFormValues {
  ownerId: string;
}

export interface OrderRecord extends OrderFormValues {
  id: string;
  createDate: number | Dayjs;
  updateDate: number | Dayjs;

  accountingStatus: OrderAccountingStatus;
  fabricationStatus: OrderFabricationStatus;
  type: OrderType;
  totalPrice: number;
  netPrice: number;
  ownerId: string;
  owner: OwnerRecord;
  apartment: ApartmentRecord;
  season?: SeasonRecord;
}

const MaxWidthDatePicker = styled(DatePicker)`
  width: 100%;
`;

const MaxWidthInputNumber = styled(InputNumber)`
  width: 100%;
`;

export interface OrderLineFormValues {
  quantity: number;
  article: ArticleRecord;
  discount?: number;
  cards?: CardFormValues[];
  customNetPrice?: number;
  id?: string;
}

export type Composition = {lines: OrderLineFormValues[]; price: number};

const orderInitialValues: Partial<OrderFormValues> = {};

export const renderOrderNumber = (
  order: Pick<OrderRecord, 'orderNumber' | 'comment'>,
) => {
  if (!order) return null;
  if (!order.comment) return order.orderNumber;
  return (
    <span>
      {order.orderNumber}{' '}
      <Tooltip title={order.comment}>
        <FileTextOutlined />
      </Tooltip>
    </span>
  );
};

export interface OrderFormProps extends TableFormProps<OrderFormValues> {
  orderLines?: OrderLineFormValues[];
  onOwnerChange?: (owner?: OwnerRecord) => void;
  onApartmentChange?: (apartment?: ApartmentRecord) => void;
  onSeasonChange?: (apartment?: SeasonRecord) => void;
  onArticleChange?: (article?: ArticleRecord) => void;
  onAdditionalArticleChange?: (article?: ArticleRecord) => void;
  onArticleTypeChange?: (articleType?: ArticleType) => void;
  onOrderLinesChange?: (orderLines?: OrderLineFormValues[]) => void;
  onIsRenewalChange?: (isRenewal: boolean) => void;
  onPaymentTypeChange?: (paymentType?: PaymentType) => void;
  admin?: true;
}

const filterArticles = (
  articles: ArticleRecord[],
  apartment: ApartmentRecord,
  articleType?: ArticleType,
  season?: SeasonRecord,
): ArticleRecord[] =>
  articles.filter(
    (article) =>
      (!articleType || article.type === articleType) &&
      (!isArticleValuesQuota(article) ||
        apartment.beddings === article.beddingQuota) &&
      (!isArticleRecordSeasonal(article) ||
        !season ||
        article.seasonId === season.id),
  );

export const OrderForm: FC<OrderFormProps> = ({
  form,
  orderLines = [],
  onFinish,
  onOwnerChange,
  onApartmentChange,
  onSeasonChange,
  onArticleChange,
  onAdditionalArticleChange,
  onArticleTypeChange,
  onOrderLinesChange,
  onIsRenewalChange,
  onPaymentTypeChange,
  initialValues = {},
  admin = false,
}) => {
  const api = useApi();
  const {t, lang} = useTranslation();

  useMemo(() => {
    form.setFieldsValue({...orderInitialValues, ...initialValues});
  }, [form, initialValues]);

  const [apartments, setApartments] = useState<ApartmentRecord[]>();
  const [apartmentsLoading, setApartmentsLoading] = useState(!!apartments);

  const [seasons, setSeasons] = useState<SeasonRecord[]>();
  const [seasonsLoading, setSeasonsLoading] = useState(!!seasons);

  const [articles, setArticles] = useState<ArticleRecord[]>();
  const [articlesLoading, setArticlesLoading] = useState(!!articles);

  const [owner, setOwner] = useState<OwnerRecord>();

  const ownerIdValue = Form.useWatch('ownerId', form);
  const apartmentIdValue = Form.useWatch('apartmentId', form);
  const articleTypeValue = Form.useWatch('articleType', form);
  const seasonIdValue = Form.useWatch('seasonId', form);
  const articleIdValue = Form.useWatch('articleId', form);
  const articleQuantityValue = Form.useWatch('articleQuantity', form);
  const isProxyValue = Form.useWatch('isProxy', form);
  const paymentTypeValue = Form.useWatch('paymentType', form);
  const isRenewal = Form.useWatch('isRenewal', form);
  const iHaveAClone = Form.useWatch('iHaveAClone', form);

  useMemo(() => {
    if (!ownerIdValue || !api) {
      onOwnerChange?.();
      return;
    }

    (async () => {
      const owner = await api.get(
        DEFAULT_API_NAME,
        `/owner/${ownerIdValue}`,
        {},
      );
      setOwner(owner);
      onOwnerChange?.(owner);
    })();
  }, [ownerIdValue, api, onOwnerChange]);

  const apartment: ApartmentRecord | undefined = useMemo(() => {
    console.log('arparmtnet');
    if (!apartmentIdValue || !apartments) return;
    const apartment = apartments.find(({id}) => id === apartmentIdValue);

    const apartmentCode = apartment?.code ?? '';
    form.setFieldsValue({apartmentCode});

    onApartmentChange?.(apartment);
    return apartment;
  }, [form, apartmentIdValue, apartments, onApartmentChange]);

  useEffect(() => {
    if (owner && owner.displayRecipientFields && apartment) {
      const values = form.getFieldsValue();
      form.setFieldsValue({
        recipientAddr: values.recipientAddr
          ? values.recipientAddr
          : 'VILLAGE CAP ESTEREL – APPT N°' + (apartment?.code ?? ''),
        recipientZipCode: values.recipientZipCode
          ? values.recipientZipCode
          : '83530',
        recipientCity: values.recipientCity
          ? values.recipientCity
          : 'AGAY SAINT RAPHAEL',
        recipientCountry: values.recipientCountry
          ? values.recipientCountry
          : 'FR',
      });
    }
  }, [owner, form, apartment]);

  const season: SeasonRecord | undefined = useMemo(() => {
    if (!seasonIdValue || !seasons) return;
    const season = seasons.find(({id}) => id === seasonIdValue);

    onSeasonChange?.(season);
    if (!season) return;
    if (SeasonalArticleType.includes(articleTypeValue)) {
      form.setFieldsValue({
        validityStart: TimestampToDayjsDate(season.dateStart),
        validityEnd: TimestampToDayjsDate(season.dateEnd),
      });
    }

    return season;
  }, [seasonIdValue, seasons, onSeasonChange, articleTypeValue, form]);

  const filteredArticles = useMemo(() => {
    const filteredArticles =
      articles && apartment
        ? filterArticles(articles, apartment, articleTypeValue, season)
        : [];

    if (
      articleIdValue &&
      !filteredArticles.find(({id}) => id === articleIdValue)
    ) {
      form.resetFields(['articleId']);
    }
    return filteredArticles;
  }, [apartment, articleIdValue, articleTypeValue, articles, form, season]);

  const article: ArticleRecord | undefined = useMemo(() => {
    if (!apartment) return;

    /* if (!articleIdValue) {
      if ((filteredArticles ?? []).length === 1) {
        form.setFieldValue('articleId', filteredArticles[0].id);
      }
      return;
    } */
    let article = (filteredArticles ?? []).find(
      ({id}) => id === articleIdValue,
    );
    if (
      !admin &&
      !article &&
      articleTypeValue === ArticleType.POOL_GOLD &&
      season
    ) {
      article = filteredArticles[0];
      const additionalArticles = filterArticles(
        articles ?? [],
        apartment,
        ArticleType.POOL_GOLD_ADDITIONAL,
        season,
      );
      onAdditionalArticleChange?.(additionalArticles[0]);
    }

    onArticleChange?.(article);
    if (!article) return article;
    if (!isArticleRecordSeasonal(article)) {
      form.resetFields(['validityStart', 'validityEnd']);
    }

    return article;
  }, [
    apartment,
    filteredArticles,
    admin,
    articleTypeValue,
    season,
    onArticleChange,
    articleIdValue,
    articles,
    onAdditionalArticleChange,
    form,
  ]);

  useEffect(() => {
    // if user selects an article type that has 1 article && this article
    // is seasonal, try to set the season to "article.seasonId"
    if (filteredArticles && filteredArticles.length === 1) {
      const article = filteredArticles[0];
      if (isArticleRecordSeasonal(article)) {
        form.setFieldValue('seasonId', article.seasonId);
      }
    }
  }, [filteredArticles, form]);

  const fetchApartments = useCallback(async () => {
    if (!api || (admin && !ownerIdValue)) return;

    setApartmentsLoading(true);
    setApartments(undefined);

    form.resetFields(['apartmentId']);
    try {
      const apartments = ((
        await api.get(
          DEFAULT_API_NAME,
          admin ? `/apartments?f_ownerId=${ownerIdValue}` : '/apartments/me',
          {},
        )
      )?.entities ?? []) as ApartmentRecord[];

      const apartmentValues = apartments.map(flattenObject);
      setApartments(apartmentValues);

      if (
        /* !form.getFieldValue('apartmentId') &&  */
        apartmentValues.length === 1
      ) {
        form.setFieldValue('apartmentId', apartmentValues[0].id);
      }

      return apartments;
    } finally {
      setApartmentsLoading(false);
    }
  }, [admin, api, form, ownerIdValue]);

  const fetchSeasons = useCallback(async () => {
    if (!api) return;

    setSeasonsLoading(true);
    setSeasons(undefined);

    try {
      const seasons = ((await api.get(DEFAULT_API_NAME, '/seasons', {}))
        ?.entities ?? []) as SeasonRecord[];

      const seasonsValues = seasons.map(flattenObject);
      setSeasons(seasonsValues);

      return seasons;
    } finally {
      setSeasonsLoading(false);
    }
  }, [api]);

  const fetchArticles = useCallback(async () => {
    if (!api) return;

    setArticlesLoading(true);
    setArticles(undefined);

    try {
      const articles = ((
        await api.get(DEFAULT_API_NAME, ['/articles'].join('/'), {})
      )?.entities ?? []) as ApartmentRecord[];

      setArticles(articles.map(flattenObject));

      return articles;
    } finally {
      setArticlesLoading(false);
    }
  }, [api]);

  useMemo(() => {
    if (!api) return;

    (async () => {
      return Promise.all([fetchApartments(), fetchSeasons(), fetchArticles()]);
    })();
  }, [api, fetchApartments, fetchSeasons, fetchArticles]);

  const handleValidityChange = () => {
    if (admin) return;
    if (
      !PeriodicArticleType.includes(articleTypeValue) ||
      !filteredArticles?.length
    )
      return;
    const {validityStart, validityEnd} = form.getFieldsValue();
    if (!validityStart || !validityEnd) return;

    const [start, end] = [validityStart, validityEnd].map(TimestampToDayjsDate);

    const compositions = getOrderCompositions(start, end, filteredArticles);
    const [bestComposition] = compositions;

    const orderLines = bestComposition?.lines ?? [];
    onOrderLinesChange?.(orderLines);

    if (!orderLines.length) return;

    const effectiveEnd = getEffectiveEndDate(start, bestComposition);
    form.setFieldValue('effectiveValidityEnd', effectiveEnd);
  };

  // adds "PARKCLONE" order line to order if "i have a clone" is selected
  useEffect(() => {
    const ARTICLE_CODE = 'PARKCLONE';

    if (orderLines.length === 0) {
      return;
    }
    if (!articles || articles.length === 0) {
      return;
    }

    // if the selected type is already PARKING_CLONE, we don't want to
    // automatically add/remove order line where type === PARKING_CLONE
    if (articleTypeValue === ArticleType.PARKING_CLONE) {
      return;
    }

    const cloneArticle = articles?.find(
      (article) => article.code === ARTICLE_CODE,
    );
    if (!cloneArticle) {
      console.warn('cannot find PARKCLONE article');
      return;
    }

    const cloneLine = orderLines.find(
      (line) => line.article.code === ARTICLE_CODE,
    );

    if (iHaveAClone) {
      // PARKCLONE already added in lines
      if (!!cloneLine) {
        return;
      }

      const newOrderLines = [
        ...orderLines,
        {article: cloneArticle, quantity: 1},
      ];
      onOrderLinesChange?.(newOrderLines);
    } else {
      // PARKCLONE already not there
      if (!cloneLine) {
        return;
      }

      const newOrderLines = orderLines.filter(
        (line) => line.article.code !== ARTICLE_CODE,
      );
      onOrderLinesChange?.(newOrderLines);
    }
  }, [
    form,
    articles,
    onOrderLinesChange,
    orderLines,
    iHaveAClone,
    articleTypeValue,
  ]);

  // @TODO: all "useEffect" are replaced by "useMemo" in this file. This causes
  // React errors during rendering, I'm not sure why this was done this way
  // (is this a typo or is there a specific reason?)
  useMemo(() => {
    if (!article) {
      onOrderLinesChange?.([]);
      return;
    }
    onOrderLinesChange?.([{article, quantity: articleQuantityValue ?? 1}]);
  }, [article, articleQuantityValue, onOrderLinesChange]);

  const paymentTypes = useMemo(
    () => (admin ? AdminPaymentTypes : getOwnerPaymentTypes(articleTypeValue)),
    [admin, articleTypeValue],
  );

  useEffect(() => {
    // if there's only one payment type, auto-select it
    if (paymentTypes && paymentTypes.length === 1) {
      form.setFieldValue('paymentType', paymentTypes[0]);
    }
  }, [paymentTypes, form]);

  return (
    <Form<OrderFormValues>
      form={form}
      layout="vertical"
      onSubmitCapture={onFinish}
      onFinish={onFinish}
      initialValues={{...orderInitialValues, ...initialValues}}
      autoComplete="off"
    >
      {admin ? (
        <Form.Item
          name="ownerId"
          label={t('orders.owner')}
          rules={[{required: true}]}
        >
          <OwnerSelect allowClear />
        </Form.Item>
      ) : null}

      {!admin || ownerIdValue ? (
        <>
          <Row gutter={20}>
            <Col span={6}>
              <Form.Item label={t('orders.createDate')}>
                <MaxWidthDatePicker
                  disabled
                  value={dayjs()}
                  format={getDateFormat(lang)}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="apartmentId"
                label={t('orders.apartment')}
                rules={[{required: true}]}
                help={
                  apartment
                    ? `${t('apartments.beddings')}: ${apartment.beddings}`
                    : null
                }
                className="help-text-green"
              >
                <Select<ApartmentRecord>
                  loading={apartmentsLoading}
                  options={apartments?.map(({id, code}) => ({
                    value: id,
                    label: code,
                  }))}
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item name="apartmentCode" hidden>
            <Input />
          </Form.Item>
        </>
      ) : null}
      {apartmentIdValue ? (
        <Row gutter={20}>
          <Col span={12}>
            <Form.Item
              name="articleType"
              label={t('orders.articleType')}
              rules={[{required: true}]}
            >
              <Select<ArticleType>
                onChange={onArticleTypeChange}
                options={Object.entries(ArticleTypeLabel)
                  .filter(
                    ([type]) =>
                      admin ||
                      OwnerSelectableArticleTypes.includes(Number(type)),
                  )
                  .map(([type, label]) => ({
                    value: Number(type),
                    label: t(label),
                  }))}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            {articleTypeValue &&
            SeasonalArticleType.includes(articleTypeValue) ? (
              <Form.Item
                name="seasonId"
                label={t('orders.season')}
                rules={[{required: true}]}
              >
                <Select<ApartmentRecord>
                  loading={seasonsLoading}
                  options={seasons?.map(({id, code}) => ({
                    value: id,
                    label: code,
                  }))}
                />
              </Form.Item>
            ) : null}
          </Col>
        </Row>
      ) : null}
      {!!articleTypeValue ? (
        <Form.Item
          name="isRenewal"
          label={t('orders.isRenewal')}
          valuePropName="checked"
          className="force-horizontal"
        >
          <Checkbox onChange={(e) => onIsRenewalChange?.(e.target.checked)} />
        </Form.Item>
      ) : null}
      {!!articleTypeValue &&
      isRenewal &&
      articleTypeValue !== ArticleType.POOL_GOLD &&
      articleTypeValue !== ArticleType.POOL_GOLD_ADDITIONAL &&
      articleTypeValue !== ArticleType.POOL_BLANCHE ? (
        <Form.Item
          name="renewalCardNo"
          label={t('orders.renewalCardNo')}
          // rules={[{required: true}]}
        >
          <Input />
        </Form.Item>
      ) : null}
      {!!articleTypeValue &&
      isRenewal &&
      (articleTypeValue === ArticleType.POOL_GOLD ||
        articleTypeValue === ArticleType.POOL_GOLD_ADDITIONAL) ? (
        <div style={{color: red[5], marginBottom: 24}}>
          {t('orders.renewalCardNo.gold')}
        </div>
      ) : null}
      {articleTypeValue &&
      (admin ||
        (ArticleTypeRequireArticleSelection.includes(articleTypeValue) &&
          (season || !SeasonalArticleType.includes(articleTypeValue)))) ? (
        <Row gutter={20}>
          <Col span={14}>
            <Form.Item
              name="articleId"
              label={t('orders.article')}
              rules={[{required: true}]}
              help={
                admin && article
                  ? `${t('orderLines.unitPrice')}: ${formatCurrency(
                      article?.unitPriceInclVAT ?? 0,
                      lang,
                    )}`
                  : null
              }
              className="help-text-green"
            >
              {filteredArticles.length ? (
                <Select<ArticleRecord>
                  loading={articlesLoading}
                  options={filteredArticles?.map(({id, label, code}) => ({
                    value: id,
                    label: `${label} (${code})`,
                  }))}
                />
              ) : (
                <Alert type="error" message={t('order.error.noArticles')} />
              )}
            </Form.Item>
          </Col>
          {admin &&
          articleTypeValue !== ArticleType.POOL_BLANCHE &&
          !ArticleTypeRequireNamedCards.includes(articleTypeValue) ? (
            <Col span={10}>
              <Form.Item
                name="articleQuantity"
                label={t('orders.articleQuantity')}
                rules={[{required: true}]}
              >
                <MaxWidthInputNumber />
              </Form.Item>
            </Col>
          ) : null}
        </Row>
      ) : null}
      {article ||
      (articleTypeValue &&
        !ArticleTypeRequireArticleSelection.includes(articleTypeValue) &&
        (season || !SeasonalArticleType.includes(articleTypeValue))) ? (
        <>
          {ArticleTypeRequiresValidityInterval.includes(articleTypeValue) ? (
            <Row gutter={20}>
              <Col span={12}>
                <Form.Item
                  name="validityStart"
                  label={t('orders.validityStart')}
                  rules={[{required: true}]}
                >
                  <MaxWidthDatePicker
                    disabled={
                      !admin && articleTypeValue !== ArticleType.PARKING
                    }
                    onChange={handleValidityChange}
                    format={getDateFormat(lang)}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="validityEnd"
                  label={t('orders.validityEnd')}
                  rules={[
                    {required: true},
                    getIntervalEndDateRule({
                      startDateFieldName: 'validityStart',
                      t,
                    }),
                  ]}
                >
                  <MaxWidthDatePicker
                    disabled={
                      !admin && articleTypeValue !== ArticleType.PARKING
                    }
                    onChange={handleValidityChange}
                    format={getDateFormat(lang)}
                  />
                </Form.Item>
              </Col>
            </Row>
          ) : null}

          <Form.Item name="effectiveValidityEnd" hidden>
            <MaxWidthDatePicker />
          </Form.Item>

          {articleTypeValue === ArticleType.PARKING ||
          articleTypeValue === ArticleType.LOST_PARKING ? (
            <Form.Item
              name="iHaveAClone"
              label={t('orders.iHaveAClone')}
              valuePropName="checked"
              className="force-horizontal"
            >
              <Checkbox />
            </Form.Item>
          ) : undefined}

          {!admin && ArticleTypeAllowsProxy.includes(articleTypeValue) ? (
            <Form.Item
              name="isProxy"
              label={t('orders.isProxy.form')}
              valuePropName="checked"
              className="force-horizontal"
            >
              <Checkbox />
            </Form.Item>
          ) : null}

          {isProxyValue ? (
            <>
              <Form.Item
                name="proxy"
                label={t('orders.proxy')}
                rules={[{required: true}]}
              >
                <Input />
              </Form.Item>

              <Form.Item>
                <a href={ORDER_PROXY_PDF_PATH} target="_blank" rel="noreferrer">
                  <Button>{t('orders.proxyTemplateDownload')}</Button>
                </a>
              </Form.Item>

              <Form.Item
                name="proxyDocuments"
                label={t('orders.proxyFile')}
                valuePropName="fileList"
                rules={[{required: true}]}
                getValueFromEvent={(event: {
                  file: UploadFile;
                  fileList: UploadFile[];
                }) => event?.fileList}
              >
                <Upload
                  listType="picture-card"
                  accept={proxyMimeTypes.join(',')}
                  action={async () => ''}
                  customRequest={({onSuccess}) =>
                    setTimeout(() => {
                      onSuccess?.('ok');
                    }, 0)
                  }
                  maxCount={1}
                  showUploadList={{
                    showRemoveIcon: true,
                    showPreviewIcon: false,
                    showDownloadIcon: false,
                  }}
                  beforeUpload={(file) => {
                    if (!proxyMimeTypes.includes(file.type)) {
                      message.error(t('general.invalidUploadFileType'));
                      return false;
                    }
                    return true;
                  }}
                >
                  {t('general.upload')}
                </Upload>
              </Form.Item>
            </>
          ) : null}

          {owner?.displayRecipientFields ? (
            <div
            // style={{padding: 12, border: `2px solid ${COLORS.PRIVATE_COLOR}`}}
            >
              <Divider>{t('orders.recipient')}</Divider>

              <Form.Item
                name="recipientName"
                label={t('orders.recipientName')}
                rules={[{required: true}]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="recipientAddr"
                label={t('orders.recipientAddr')}
                // rules={[{required: true}]}
              >
                <Input.TextArea autoSize={{minRows: 2, maxRows: 4}} />
              </Form.Item>

              <Form.Item
                label={t('orders.recipientZipCodeAndCity')}
                style={{marginBottom: 0}}
                // required
              >
                <Form.Item
                  name="recipientZipCode"
                  // rules={[{required: true}]}
                  style={{display: 'inline-block', width: '80px'}}
                >
                  <Input placeholder={t('orders.recipientZipCode')} />
                </Form.Item>

                <Form.Item
                  name="recipientCity"
                  // rules={[{required: true}]}
                  style={{
                    display: 'inline-block',
                    width: 'calc(100% - 8px - 80px)',
                    margin: '0 0 0 8px',
                  }}
                >
                  <Input placeholder={t('orders.recipientCity')} />
                </Form.Item>
              </Form.Item>

              <Form.Item
                name="recipientCountry"
                label={t('orders.recipientCountry')}
                // rules={[{required: true}]}
              >
                <CountrySelect />
              </Form.Item>
            </div>
          ) : null}

          <Form.Item name="comment" label={t('orders.comment')}>
            <Input.TextArea autoSize={{minRows: 3, maxRows: 4}} />
          </Form.Item>

          <Row>
            <Col span={12}>
              {orderLines?.length &&
              orderLines.find(
                ({article: {unitPriceInclVAT = 0}}) => unitPriceInclVAT > 0,
              ) ? (
                <Form.Item
                  name="paymentType"
                  label={t('orders.paymentType')}
                  rules={[{required: true}]}
                >
                  <Radio.Group
                    onChange={(e) => onPaymentTypeChange?.(e.target.value)}
                  >
                    <Space direction="vertical">
                      {paymentTypes.map((paymentType, index) => (
                        <Radio value={paymentType} key={index}>
                          {t(PaymentTypeLabel[paymentType])}
                        </Radio>
                      ))}
                    </Space>
                  </Radio.Group>
                </Form.Item>
              ) : null}
            </Col>

            {!admin && paymentTypeValue === PaymentType.WIRE_TRANSFER ? (
              <Col span={24}>
                <Form.Item>
                  <WireTransferInstructions articleType={articleTypeValue} />
                </Form.Item>
              </Col>
            ) : null}

            {!admin && paymentTypeValue === PaymentType.CHECK ? (
              <Col span={24}>
                <Form.Item>
                  <CheckInstructions />
                </Form.Item>
              </Col>
            ) : null}

            {[PaymentType.SUMUP, PaymentType.SOGE].includes(
              paymentTypeValue,
            ) ? (
              <Col span={12}>
                <Form.Item>
                  <LockOutlined /> {t('orders.securePayment')}
                </Form.Item>
              </Col>
            ) : null}

            {admin && paymentTypeValue === PaymentType.CHECK ? (
              <Form.Item
                name="checkNumber"
                label={t('orders.checkNumber')}
                rules={[{required: true}]}
              >
                <Input />
              </Form.Item>
            ) : null}
          </Row>
        </>
      ) : null}
      <Form.Item hidden>
        <Button htmlType="submit">{t('general.submit')}</Button>
      </Form.Item>
    </Form>
  );
};

export const getOrderColumns = (
  t: TranslateFn,
  lang: LANGUAGE,
): TableColumnsProp<OrderRecord> => [
  {
    title: t('orders.createDate.short'),
    key: 'createDate',
    dataIndex: 'createDate',
    sorter: true,
    render: (createDate) => TimestampToDayjsDate(createDate).format('L'),
    className: 'align-title-center',
  },
  {
    title: t('orders.orderNumber'),
    key: 'orderNumber',
    dataIndex: 'orderNumber',
    sorter: true,
    className: 'align-title-center',
  },
  {
    title: t('orders.apartment'),
    key: 'apartmentId',
    dataIndex: 'apartmentId',
    sorter: true,
    render: (_, {apartment}) => apartment?.code,
    className: 'align-title-center',
  },
  {
    title: t('orders.articleType'),
    key: 'articleType',
    dataIndex: 'articleType',
    sorter: true,
    render: (articleType: ArticleType) => t(ArticleTypeLabel[articleType]),
    className: 'align-title-center',
  },
  {
    title: t('orders.netPrice'),
    key: 'netPrice',
    dataIndex: 'netPrice',
    sorter: true,
    render: (netPrice: number) => formatCurrency(netPrice, lang),
    className: 'align-title-center',
    align: 'right',
  },
  {
    title: t('orders.accountingStatus'),
    key: 'accountingStatus',
    dataIndex: 'accountingStatus',
    sorter: true,
    render: (accountingStatus: OrderAccountingStatus) =>
      t(OrderAccountingStatusLabel[accountingStatus]),
    className: 'align-title-center',
  },
  {
    title: t('orders.fabricationStatus'),
    key: 'fabricationStatus',
    dataIndex: 'fabricationStatus',
    sorter: true,
    render: (fabricationStatus: OrderFabricationStatus) =>
      t(OrderFabricationStatusLabel[fabricationStatus]),
    className: 'align-title-center',
  },
];

const parseOrderValues = ({
  validityStart: _validityStart,
  validityEnd: _validityEnd,
  createDate,
  ...values
}: OrderRecord): OrderFormValues => {
  if (
    !ArticleTypeRequiresValidityInterval.includes(values.articleType) ||
    !_validityStart ||
    !_validityEnd
  ) {
    return values;
  }

  const [validityStart, validityEnd] = DayjsDateRangeToTimestampRange(
    _validityStart,
    _validityEnd,
  );

  return {
    validityStart,
    validityEnd,
    ...values,
  };
};

export const Orders: FC = () => {
  const {t, lang} = useTranslation();
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [editEntity, setEditEntity] = useState<OrderRecord | undefined>();
  const [refreshDate, setRefreshDate] = useState<number>();
  const [total, setTotal] = useState<number>();

  const defaultModalProps = {
    singularRoute,
    pluralRoute,
    FormComponent: OrderForm,
    closable: false,
    maskClosable: false,
    cancelText: t('general.cancel'),
  };

  const columns = getOrderColumns(t, lang);
  const defaultSorter = getDefaultSorter(columns, 'orderNumber');

  return (
    <ContentDiv
      title={total ? `${t('orders.title')}: ${total}` : t('orders.title')}
      titleRightComponent={
        <Button
          type="primary"
          onClick={() => setCreateModalOpen(true)}
          icon={<PlusOutlined />}
        />
      }
    >
      <TableList<OrderRecord>
        singularRoute={singularRoute}
        pluralRoute={pluralRoute}
        columns={columns}
        refreshDate={refreshDate}
        setEditEntity={setEditEntity}
        defaultSorter={defaultSorter}
        onTotalChange={setTotal}
      />
      <TableCreateModal<OrderRecord>
        {...defaultModalProps}
        open={createModalOpen}
        title={t('general.addTitle')}
        okText={t('general.add')}
        onCancel={() => setCreateModalOpen(false)}
        onOk={() => {
          setCreateModalOpen(false);
          setRefreshDate(Date.now());
        }}
        parseValues={parseOrderValues}
      />
      <TableUpdateModal<OrderRecord>
        {...defaultModalProps}
        entity={editEntity}
        open={!!editEntity}
        title={t('general.updateTitle')}
        okText={t('general.update')}
        onCancel={() => setEditEntity(undefined)}
        onOk={() => {
          setEditEntity(undefined);
          setRefreshDate(Date.now());
        }}
        parseValues={parseOrderValues}
      />
    </ContentDiv>
  );
};

export const openInvoiceFileInNewTab = async (
  api: typeof API,
  orderId: string,
): Promise<void> => openApiUrlInNewTab(api, `/order/${orderId}/invoice`);

export const openProxyFileInNewTab = async (
  api: typeof API,
  orderId: string,
): Promise<void> => openApiUrlInNewTab(api, `/order/${orderId}/proxy`);

export const AdminOrderForm: FC<TableFormProps<OrderRecord>> = ({
  form,
  onFinish,
  initialValues = {},
}) => {
  const {t, lang} = useTranslation();
  const api = useApi();

  // const ownerId = useMemo(() => initialValues?.ownerId, [initialValues]);
  // const ownerData = useGetOwner(ownerId);
  // const shouldDisplayRecipientFields =
  //   ownerData?.data?.displayRecipientFields ?? false;
  const shouldDisplayRecipientFields = !!initialValues?.recipientName;

  useMemo(() => {
    const {createDate, validityStart, validityEnd, ...other} = {
      ...orderInitialValues,
      ...initialValues,
    } as OrderRecord;

    form.setFieldsValue({
      ...other,
      createDate: createDate ? TimestampToDayjsDate(createDate) : undefined,
      validityStart: validityStart
        ? TimestampToDayjsDate(validityStart)
        : undefined,
      validityEnd: validityEnd ? TimestampToDayjsDate(validityEnd) : undefined,
    });
  }, [form, initialValues]);

  const proxyValue = Form.useWatch('proxy', form);
  const [proxyLoading, setProxyLoading] = useState(false);
  const handleProxyFileClick = async () => {
    if (!api || !initialValues?.id || !initialValues?.proxy) return;
    setProxyLoading(true);
    try {
      openProxyFileInNewTab(api, initialValues.id);
    } finally {
      setProxyLoading(false);
    }
  };

  return (
    <Form<OrderFormValues>
      form={form}
      layout="vertical"
      onSubmitCapture={onFinish}
      onFinish={onFinish}
      initialValues={{...orderInitialValues, ...initialValues}}
      autoComplete="off"
    >
      <Row gutter={20}>
        <Col span={12}>
          <Form.Item
            name="createDate"
            label={t('orders.createDate')}
            rules={[{required: true}]}
          >
            <MaxWidthDatePicker disabled format={getDateFormat(lang)} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="orderNumber"
            label={t('orders.orderNumber')}
            rules={[{required: true}]}
          >
            <Input disabled />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={20}>
        <Col span={12}>
          <Form.Item
            name="validityStart"
            label={t('orders.validityStart')}
            rules={[{required: true}]}
          >
            <MaxWidthDatePicker disabled format={getDateFormat(lang)} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="validityEnd"
            label={t('orders.validityEnd')}
            rules={[{required: true}]}
          >
            <MaxWidthDatePicker disabled format={getDateFormat(lang)} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={20}>
        <Col span={12}>
          <Form.Item
            name="fabricationStatus"
            label={t('orders.fabricationStatus')}
            rules={[{required: true}]}
          >
            <Select<OrderFabricationStatus>
              options={Object.entries(OrderFabricationStatusLabel).map(
                ([type, label]) => ({
                  value: Number(type),
                  label: t(label),
                }),
              )}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="accountingStatus"
            label={t('orders.accountingStatus')}
            rules={[{required: true}]}
          >
            <Select<OrderAccountingStatus>
              options={Object.entries(OrderAccountingStatusLabel).map(
                ([type, label]) => ({
                  value: Number(type),
                  label: t(label),
                }),
              )}
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        name="articleType"
        label={t('orders.articleType')}
        rules={[{required: true}]}
      >
        <Select<ArticleType>
          disabled
          options={Object.entries(ArticleTypeLabel).map(([type, label]) => ({
            value: Number(type),
            label: t(label),
          }))}
        />
      </Form.Item>
      <Row gutter={20}>
        <Col span={12}>
          <Form.Item
            name="paymentType"
            label={t('orders.paymentType')}
            rules={[{required: true}]}
          >
            <Select<PaymentType>
              disabled
              options={Object.entries(PaymentTypeLabel).map(
                ([type, label]) => ({
                  value: Number(type),
                  label: t(label),
                }),
              )}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="type"
            label={t('orders.type')}
            rules={[{required: true}]}
          >
            <Select<OrderType>
              disabled
              options={Object.entries(OrderTypeLabel).map(([type, label]) => ({
                value: Number(type),
                label: t(label),
              }))}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={20}>
        <Col span={12}>
          <Form.Item name={'checkoutId'} label={t('orders.checkoutId')}>
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={'checkNumber'} label={t('orders.checkNumber')}>
            <Input disabled />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={20}>
        <Col flex={1}>
          <Form.Item name="proxy" label={t('orders.proxy')}>
            <Input disabled />
          </Form.Item>
        </Col>
        {proxyValue ? (
          <Col>
            <Button
              style={{marginTop: 30}}
              icon={<UserOutlined />}
              type="default"
              title={t('orders.proxy')}
              loading={proxyLoading}
              onClick={handleProxyFileClick}
            />
          </Col>
        ) : null}
      </Row>

      <Row gutter={20}>
        <Col span={12}>
          <Form.Item name="totalPrice" label={t('orders.totalPrice')}>
            <Input disabled addonAfter="€" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="netPrice" label={t('orders.netPrice')}>
            <Input disabled addonAfter="€" />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item name="apartmentId" rules={[{required: true}]} hidden>
        <Input disabled />
      </Form.Item>

      <Form.Item name="seasonId" hidden>
        <Input disabled />
      </Form.Item>

      <Form.Item name="ownerId" rules={[{required: true}]} hidden>
        <Input disabled />
      </Form.Item>

      <Form.Item
        label={`${t('orders.isRenewal')} - ${t('orders.renewalCardNo')}`}
        style={{marginBottom: 0}}
        // required
      >
        <Form.Item
          name="isRenewal"
          // rules={[{required: true}]}
          style={{display: 'inline-block', width: '20px'}}
          valuePropName="checked"
        >
          <Checkbox disabled />
        </Form.Item>

        <Form.Item
          name="renewalCardNo"
          // rules={[{required: true}]}
          style={{
            display: 'inline-block',
            width: 'calc(100% - 8px - 20px)',
            margin: '0 0 0 8px',
          }}
        >
          <Input /*placeholder={t('orders.renewalCardNo')}*/ disabled />
        </Form.Item>
      </Form.Item>

      <Form.Item
        name="iHaveAClone"
        valuePropName="checked"
        label={t('orders.iHaveAClone')}
        className="force-horizontal"
      >
        <Checkbox disabled />
      </Form.Item>

      {shouldDisplayRecipientFields ? (
        <div
        // style={{padding: 12, border: `2px solid ${COLORS.PRIVATE_COLOR}`}}
        >
          <Divider>{t('orders.recipient')}</Divider>

          <Form.Item
            name="recipientName"
            label={t('orders.recipientName')}
            // rules={[{required: true}]}
          >
            <Input disabled />
          </Form.Item>

          <Form.Item
            name="recipientAddr"
            label={t('orders.recipientAddr')}
            // rules={[{required: true}]}
          >
            <Input.TextArea autoSize={{minRows: 2, maxRows: 4}} disabled />
          </Form.Item>

          <Form.Item
            label={t('orders.recipientZipCodeAndCity')}
            style={{marginBottom: 0}}
            // required
          >
            <Form.Item
              name="recipientZipCode"
              // rules={[{required: true}]}
              style={{display: 'inline-block', width: '80px'}}
            >
              <Input placeholder={t('orders.recipientZipCode')} disabled />
            </Form.Item>

            <Form.Item
              name="recipientCity"
              // rules={[{required: true}]}
              style={{
                display: 'inline-block',
                width: 'calc(100% - 8px - 80px)',
                margin: '0 0 0 8px',
              }}
            >
              <Input placeholder={t('orders.recipientCity')} disabled />
            </Form.Item>
          </Form.Item>

          <Form.Item
            name="recipientCountry"
            label={t('orders.recipientCountry')}
            // rules={[{required: true}]}
          >
            <CountrySelect disabled />
          </Form.Item>
        </div>
      ) : null}

      <Form.Item hidden>
        <Button htmlType="submit">{t('general.submit')}</Button>
      </Form.Item>
    </Form>
  );
};

export const getOrderAdminColumns = (
  t: TranslateFn,
  lang: LANGUAGE,
): TableColumnsProp<OrderRecord> => [
  {
    title: t('orders.createDate.short'),
    key: 'createDate',
    dataIndex: 'createDate',
    render: (createDate) => TimestampToDayjsDate(createDate).format('L'),
    sorter: true,
  },
  {
    title: t('orders.orderNumber'),
    key: 'orderNumber',
    dataIndex: 'orderNumber',
    searchInput: true,
    sorter: true,
    render: (_, order) => renderOrderNumber(order),
  },
  {
    title: t('orders.owner'),
    key: 'ownerId',
    dataIndex: 'ownerId',
    sorter: true,
    render: (_, {owner}) =>
      owner ? (
        owner?.user ? (
          <Link to={`/admin/owner/${owner.id}`}>
            {owner?.user?.lastName} {owner?.user?.firstName}
          </Link>
        ) : (
          <Link to={`/admin/owner/${owner.id}`}>{owner.id}</Link>
        )
      ) : null,
    searchInput: true,
    searchSelect: (props) => <OwnerSelect {...props} />,
  },
  {
    title: t('orders.recipient'),
    key: 'recipientName',
    dataIndex: 'recipientName',
    sorter: true,
    render: (_, entity) => entity?.recipientName,
    searchInput: true,
  },
  {
    title: t('orders.apartment'),
    key: 'apartmentId',
    dataIndex: 'apartmentId',
    sorter: true,
    render: (_, {apartment}) => apartment?.code,
    searchInput: true,
    searchSelect: (props) => <ApartmentCodeSelect {...props} />,
  },
  {
    title: t('orders.articleType'),
    key: 'articleType',
    dataIndex: 'articleType',
    sorter: true,
    render: (articleType: ArticleType) => t(ArticleTypeLabel[articleType]),
  },
  {
    title: t('orders.season'),
    key: 'seasonId',
    dataIndex: 'seasonId',
    sorter: true,
    render: (_, {season}: OrderRecord) => season?.code,
  },
  /* {
    title: t('orders.orderedCards'),
    key: 'orderedCards',
    dataIndex: 'orderedCards',
  },
  {
    title: t('orders.deliveredCards'),
    key: 'deliveredCards',
    dataIndex: 'deliveredCards',
  }, */
  {
    title: t('orders.netPrice'),
    key: 'netPrice',
    dataIndex: 'netPrice',
    sorter: true,
    render: (netPrice: number) => formatCurrency(netPrice, lang),
    align: 'right',
  },
  {
    title: t('orders.accountingStatus'),
    key: 'accountingStatus',
    dataIndex: 'accountingStatus',
    sorter: true,
    render: (accountingStatus: OrderAccountingStatus) =>
      t(OrderAccountingStatusLabel[accountingStatus]),
    filters: Object.entries(OrderAccountingStatusLabel).map(
      ([type, label]) => ({
        text: t(label),
        value: type,
      }),
    ),
  },
  {
    title: t('orders.fabricationStatus'),
    key: 'fabricationStatus',
    dataIndex: 'fabricationStatus',
    sorter: true,
    render: (fabricationStatus: OrderFabricationStatus) =>
      t(OrderFabricationStatusLabel[fabricationStatus]),
    filters: Object.entries(OrderFabricationStatusLabel).map(
      ([type, label]) => ({text: t(label), value: type}),
    ),
  },
  {
    title: t('orders.isRenewal.short'),
    key: 'isRenewal',
    dataIndex: 'isRenewal',
    render: (isRenewal) =>
      isRenewal ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : null,
    filters: booleanFilters(t),
  },
];

export const AdminOrders: FC = () => {
  const {t, lang} = useTranslation();
  const api = useApi();
  const isAdmin = useUserIsAdmin();

  const [editEntity, setEditEntity] = useState<OrderRecord | undefined>();
  const [refreshDate, setRefreshDate] = useState<number>();
  const [total, setTotal] = useState<number>();

  const [orderLinesEntity, setOrderLinesEntity] = useState<
    OrderRecord | undefined
  >();

  const [proxyLoading, setProxyLoading] = useState(false);
  const handleProxyFileClick = async (order: OrderRecord) => {
    if (!api || !order.proxy) return;
    setProxyLoading(true);
    try {
      openProxyFileInNewTab(api, order.id);
    } finally {
      setProxyLoading(false);
    }
  };

  const [exportLoading, setExportLoading] = useState(false);
  const [exportFilter, setExportFilter] = useState<
    Record<string, FilterValue | null>
  >({});

  const defaultModalProps = {
    singularRoute,
    pluralRoute,
    FormComponent: AdminOrderForm,
    closable: false,
    maskClosable: false,
    cancelText: t('general.cancel'),
  };

  const columns = useStateFn(() => getOrderAdminColumns(t, lang), [t, lang]);
  const defaultSorter = useStateFn(
    () => getDefaultSorter(columns, 'orderNumber'),
    [columns],
  );

  const handleExportClick = async () => {
    if (!api) return;
    setExportLoading(true);
    try {
      const {ordersCsv, ordersLinesCsv, cardsCsv} = (await api.get(
        DEFAULT_API_NAME,
        `${pluralRoute}/export`,
        {
          queryStringParameters: {
            ...getFiltersForAPI(exportFilter, {}, columns),
          },
        },
      )) as {ordersCsv: string; ordersLinesCsv: string; cardsCsv: string};

      const fileName = `export-${new Date().toISOString().replace(/:/g, '-')}`;

      const zip = new JSZip();
      zip.file(`commandes-${fileName}.csv`, ordersCsv);
      zip.file(`lignes-de-commande-${fileName}.csv`, ordersLinesCsv);
      zip.file(`cartes-${fileName}.csv`, cardsCsv);

      const content = await zip.generateAsync({type: 'blob'});
      saveAs(content, `${fileName}.zip`);
    } finally {
      setExportLoading(false);
    }
  };

  const orderLinesForcedFilter = useStateFn(
    () =>
      !!orderLinesEntity?.id ? {orderId: [orderLinesEntity?.id]} : undefined,
    [orderLinesEntity],
  );
  const orderLinesColumns = useStateFn(
    () => getOrderLineColumns(t, lang),
    [t, lang],
  );

  return (
    <ContentDiv
      title={total ? `${t('orders.title')}: ${total}` : t('orders.title')}
      titleRightComponent={
        <Space>
          <Button
            loading={exportLoading}
            onClick={handleExportClick}
            type="primary"
          >
            {t('general.export')}
          </Button>
          <Link to="/admin/order">
            <Button type="primary" icon={<PlusOutlined />} />
          </Link>
        </Space>
      }
    >
      <TableList<OrderRecord>
        singularRoute={singularRoute}
        pluralRoute={pluralRoute}
        columns={columns}
        refreshDate={refreshDate}
        setEditEntity={setEditEntity}
        defaultSorter={defaultSorter}
        actionButtonProps={{
          delete: (order) =>
            order.accountingStatus !== OrderAccountingStatus.SUBMITTED
              ? {disabled: true}
              : {},
        }}
        customActions={[
          (entity) => (
            <Space key="actions">
              {entity.proxy ? (
                <Button
                  icon={<UserOutlined />}
                  type="default"
                  title={t('orders.proxy')}
                  loading={proxyLoading}
                  onClick={() => handleProxyFileClick(entity)}
                />
              ) : null}
              <Button
                icon={<MenuOutlined />}
                type="default"
                title={t('orderLines.title')}
                onClick={() => setOrderLinesEntity(entity)}
              />
              {isAdmin ? <GenerateInvoicePDF orderId={entity.id} /> : null}
            </Space>
          ),
        ]}
        onTotalChange={setTotal}
        onFilterChange={setExportFilter}
      />
      <TableUpdateModal<OrderRecord>
        {...defaultModalProps}
        entity={editEntity}
        open={!!editEntity}
        title={t('general.updateTitle')}
        okText={t('general.update')}
        onCancel={() => setEditEntity(undefined)}
        onOk={() => {
          setEditEntity(undefined);
          setRefreshDate(Date.now());
        }}
        parseValues={parseOrderValues}
      />

      {orderLinesEntity ? (
        <Modal
          centered
          open={!!orderLinesEntity}
          onCancel={() => setOrderLinesEntity(undefined)}
          title={t('orderLines.title')}
          cancelText={t('general.close')}
          okButtonProps={{style: {display: 'none'}}}
          width={900}
        >
          <Col span={24}>
            <p>
              {t('orders.orderNumber')} : {orderLinesEntity.orderNumber}
            </p>
            <p>
              {t('orders.owner')} : {orderLinesEntity.owner?.name}
            </p>
          </Col>
          <br />
          <Descriptions
            layout="vertical"
            size="small"
            bordered
            style={{marginBottom: 20}}
          >
            {(['validityStart', 'validityEnd'] as (keyof OrderRecord)[]).map(
              (dateProp) => (
                <Descriptions.Item
                  key={dateProp}
                  label={t(`orders.${dateProp}`)}
                >
                  {!!orderLinesEntity?.[dateProp]
                    ? TimestampToDayjsDate(
                        orderLinesEntity[dateProp] as number | Dayjs,
                      ).format('L')
                    : ''}
                </Descriptions.Item>
              ),
            )}
          </Descriptions>
          {orderLinesForcedFilter ? (
            <TableList<OrderLineRecord>
              singularRoute={'/orderLine'}
              pluralRoute={'/orderLines'}
              forcedFilter={orderLinesForcedFilter}
              columns={orderLinesColumns}
              hideActionColumn
            />
          ) : undefined}
        </Modal>
      ) : null}
    </ContentDiv>
  );
};
